.header {
    min-height: 15vh;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    color: white;
    .MuiTypography-root {
        margin-top: 64px;
    }
}
.headerHome {
    height: 100vh;
    box-sizing: border-box;
    text-align: center;
    .MuiTypography-root {
        padding: 0 5%;
        font-size: 45px;
        margin: 0 auto;
        letter-spacing: 0.25rem;
        margin-top: 64px;
    }
}

/* Small versions (initial load) */
.homeImage.smallLoaded {
    background-image: url('/resources/home-small.webp');
    background-position: 50% 25%;
}
.aboutusImage.smallLoaded {
    background-image: url('/resources/aboutus-small.webp');
    background-position: 50% 40%;
}
.faqsImage.smallLoaded {
    background-image: url('/resources/faqs-small.webp');
    background-position: 50% 38%;
}
.reviewsImage.smallLoaded {
    background-image: url('/resources/reviews-small.webp');
    background-position: 50% 80%;
}

/* Large version (once fully loaded) */
.homeImage.largeLoaded {
    background-image: url('../../resources/home.webp');
    background-position: 50% 25%;
}
.aboutusImage.largeLoaded {
    background-image: url('../../resources/aboutus.webp');
    background-position: 50% 40%;
}
.faqsImage.largeLoaded {
    background-image: url('../../resources/faqs.webp');
    background-position: 50% 38%;
}
.reviewsImage.largeLoaded {
    background-image: url('../../resources/reviews.webp');
    background-position: 50% 80%;
}

.header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.35);
}

@media (max-width: 550px) {
    .headerHome .MuiTypography-root {
        font-size: 25px;
    }
}
