.infoCard {
    max-width: 300px;
    min-height: 150px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex-grow: 1;
    padding: 4rem 1rem 1rem 1rem;
    box-sizing: border-box;
    .infoIcon {
        width: 40px;
        height: 40px;
    }
    .iconCircleOutline {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 3px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        background-color: #f3e5d8;
    }
    .titleText {
        margin-top: 3.5rem;
        color: inherit;
        font-size: 18px;
    }
    .descText {
        margin-top: 0.5rem;
        font-size: 14px;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 65px;
        background-color: #c1be7f;
        border-radius: 5px 5px 0 0;
        z-index: 1;
    }
    &.clickable {
        cursor: pointer;
        &:hover {
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        }
    }
}

@media (max-width: 1390px) {
    .infoCard .titleText {
        max-width: 200px;
    }
}
@media (max-width: 1000px) {
    .infoCard .titleText {
        font-size: 15px;
        max-width: auto;
    }
    .infoCard .descText {
        font-size: 12px;
    }
}

@media (max-width: 899px) {
    .infoCard {
        min-height: 100px;
        .titleText {
            font-size: 15px;
            margin-top: 1.5rem;
            margin-bottom: 0.25rem;
        }
        .infoIcon {
            width: 25px;
            height: 25px;
        }
        .iconCircleOutline {
            top: 10px;
            margin-top: 20px;
            width: 40px;
            height: 40px;
            border: 2px solid #000;
        }
        &::before {
            height: 55px;
        }
    }
}
@media (max-width: 750px) {
    .infoCard {
        .titleText {
            font-size: 12px;
        }
    }
}
@media (max-width: 400px) {
    .infoCard {
        .titleText {
            min-width: 95px;
        }
    }
}
