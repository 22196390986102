.introSection {
    display: flex;
    align-items: center;
    background-color: #f3e5d8;
}

.boardingSection {
    .tenalImage {
        width: 100%;
        height: 450px;
        object-fit: cover;
        border-radius: 5px;
        box-shadow: 1px 1px 3px 0.5px rgba(0, 0, 0, 0.5);
    }
}

.followSection {
    .boardingImage {
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 3px 0.5px rgba(0, 0, 0, 0.5);
        transition: box-shadow 0.3s ease;
        cursor: pointer;
        &:hover {
            box-shadow: 2px 2px 6px 0.5px rgba(0, 0, 0, 0.5);
        }
    }
}

@media (max-width: 899px) {
    .boardingSection .tenalImage {
        width: 100%;
    }
}
