.topNav {
    .logoImage {
        max-width: 50px;
        margin-right: 10px;
    }
    .logoText {
        margin-right: 2;
        letter-spacing: 0.3rem;
        text-decoration: none;
        color: white;
    }
    .navLinks {
        display: flex;
        gap: 10px;
        align-items: center;
        .navLink {
            color: white;
            text-decoration: none;
            text-align: center;
            margin-right: 15px;
            text-transform: capitalize;
            transition: color 0.2s;
            &:hover {
                color: #707070;
            }
        }
    }
    .accountIcon {
        background-color: #9f958d;
        color: #fff;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        transition: background-color 0.2s;
        cursor: auto;
        padding: 8px;
        &:hover {
            background-color: #707070;
        }
    }
    .menuToggle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 22px;
        width: 22px;
        cursor: pointer;
        .bar {
            height: 3px;
            width: 100%;
            background-color: white;
            border-radius: 1px;
            transition:
                background-color 0.3s ease,
                transform 100ms ease-in-out;
        }
        &:hover .bar {
            background-color: grey;
        }
        .x:nth-of-type(1) {
            transform: rotate(45deg);
            transform-origin: top left;
            width: 28px;
        }
        .x:nth-of-type(2) {
            opacity: 0;
            width: 0;
        }
        .x:nth-of-type(3) {
            transform: rotate(-45deg);
            transform-origin: bottom left;
            width: 28px;
        }
    }
}

.navMenu {
    position: fixed;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4vh;
    z-index: 1200;
    transition: opacity 0.3s ease-in-out;
    .menuLinks {
        text-decoration: none;
        color: black;
        font-size: calc(0.5vh + 1rem);
        text-align: center;
        transition: color 0.2s ease-in-out;
        &:hover {
            color: #c1be7f;
        }
    }
    .accountIcon {
        background-color: #9f958d;
        color: #fff;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        transition: background-color 0.2s;
        cursor: auto;
        padding: 8px;
        &:hover {
            background-color: #c1be7f;
        }
    }
}

.footer {
    min-height: 5vh;
    background-color: #f3e5d8;
    .footerLinks {
        color: rgba(0, 0, 0, 0.35);
        text-decoration: none;
        transition: color 0.2s;
        cursor: pointer;
        &:hover {
            color: #707070;
        }
    }
    .footerForm {
        color: rgba(0, 0, 0, 0.35);
        .footerInput {
            display: flex;
            flex-direction: column;
            max-width: 250px;
            .MuiInputLabel-outlined {
                color: rgba(0, 0, 0, 0.35);
            }
            margin-top: 4px;
        }
    }
    .copyright {
        color: rgba(0, 0, 0, 0.2);
        font-style: italic;
    }
}

@media (max-width: 450px) {
    .topNav {
        .logoImage {
            margin-right: 5px;
            max-width: 40px;
        }
        .logoText {
            display: none;
        }
    }
    .footer .copyright {
        font-size: 12px;
    }
}

@media (max-width: 350px) {
    .footer .copyright {
        font-size: 10px;
    }
}
