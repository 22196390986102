:root {
    --max-font-pixels: 26px;
    --min-font-pixels: 14px;
    --max-font-size: 12;
    --min-font-size: 10;
    --min-screen-width-pixels: 300px;
    --max-screen-width-pixels: 1600px;
    --max-screen-width-size: 1600;
    --min-screen-width-size: 300;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 64px;
}

ul {
    list-style: none;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.anchor {
    height: 10px;
    &.offsetTop {
        transform: scale(1.5);
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: calc(
        var(--min-font-pixels) + (var(--max-font-size) - var(--min-font-size)) *
            (
                (100vw - var(--min-screen-width-pixels)) /
                    (
                        var(--max-screen-width-size) - var(
                                --min-screen-width-size
                            )
                    )
            )
    );
    box-sizing: border-box;
}

.noMargins {
    margin: 0 !important;
}

.noPadding {
    padding: 0 !important;
}

.semiBold {
    font-weight: 500 !important;
}

.links {
    font-style: italic;
    font-weight: 500;
    transition: color 0.15s;
    &:hover {
        color: #9f9a37;
    }
}
.logoWidth {
    max-width: 105px;
}
