.review {
    position: relative;
    margin-top: 50px !important;
    .quoteLeft {
        width: 40px;
        height: 40px;
        position: absolute;
        top: -15px;
        left: -15px;
    }
    .quoteRight {
        width: 40px;
        height: 40px;
        position: absolute;
        bottom: -15px;
        right: -15px;
    }
    .imageContainer {
        position: absolute;
        top: 12px;
        width: 110px;
        height: 110px;
        background-color: #9f958d;
        border: 15px solid #d9d8b2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .svgContainer,
        .imgContainer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: none;
            &.show {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .reviewImage {
            width: 110px;
            height: 110px;
            border-radius: 50%;
            object-fit: cover;
        }
        .dogSvg {
            width: 75%;
            height: 75%;
            fill: #ffffff;
        }
    }
    .textContainer {
        position: relative;
        z-index: -1;
        background-color: #d9d8b2;
        border-radius: 20px;
        box-shadow:
            0px 2px 1px -1px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 1px rgba(0, 0, 0, 0.12);
        .reviewStars {
            color: #ffecb5;
            width: 24px;
            height: 24px;
        }
        .reviewParagraph {
            text-align: justify;
        }
        .reviewName {
            font-style: italic;
            width: 100%;
            text-align: center;
            margin-top: 30px;
        }
    }
}

.reviewFormMessage {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 525px;
}
